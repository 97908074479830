<template>
    <div v-if="isShown" class="ModalAboutUs" name="ModalAboutUs">
        <button class="ModalAboutUs-Close" @click="close">
            <img loading="lazy" src="@/assets/images/close.svg" alt="close" />
        </button>
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShown: false
        };
    },
    methods: {
        open() {
            this.isShown = true;
        },
        close() {
            this.isShown = false;
        }
    }
};
</script>


<style lang="scss">
.ModalAboutUs {
    background: var(--color-body);
    border-radius: 1%;
    display: grid;
    position: fixed;
    top: 25%;
    left: 15%;
    align-self: center;
    z-index: 2;
    padding: 26px;
    filter: drop-shadow(0 -1mm 1mm var(--color-progressbar-bg));

    @media (min-width: $screen-xxs) {
        width: 90%;
        left: 5%;
    }

    @media (min-width: $screen-xs) {
        width: 80%;
        left: 10%;
    }

    @media (min-width: $screen-s) {
        width: 100%;
    }

    @media (min-width: $screen-m) {
        width: 80%;
        left: 10%;
    }

    @media (min-width: $screen-l) {
        width: 70%;
        top: 35%;
        left: 15%;
    }

    @media (min-width: $screen-xl) {
        width: 40%;
        top: 40%;
        left: 10%;
    }

    @media (min-width: $screen-xxl) {
        width: 60%;
        top: 25%;
        left: 20%;
    }

    &-Text {
        text-align: left;
        margin-top: 5%;
        padding-bottom: 2%;
        border-bottom: 1px solid var(--color-text-main2);
        font-weight: 10;
        font-size: 24px;
        z-index: 1;

        .Shadow {
            position: absolute;
            width: 250px;
            left: 70%;
            height: 10%;
            filter: blur(7px);
            background: var(--color-body);
            z-index: 0;

            @media (min-width: $screen-xxs) {
                width: 30%;
            }

            @media (min-width: $screen-xs) {
                width: 30%;
            }

            @media (min-width: $screen-s) {
                width: 30%;
            }

            @media (min-width: $screen-m) {
                width: 30%;
            }

            @media (min-width: $screen-l) {
                width: 40%;
                left: 60%;
            }

            @media (min-width: $screen-xl) {
                width: 40%;
                left: 60%;
            }

            @media (min-width: $screen-xxl) {
                width: 50%;
                left: 50%;
            }

            @media (min-width: $screen-xs) {
                width: 50%;
                left: 50%;
            }
        }
    }

    &-Close {
        position: absolute;
        z-index: 10;
        top: 15px;
        right: 15px;
        pointer-events: auto;
        border-radius: 50%;
        padding: 1%;
        border: 2px solid var(--color-text-main2);

        img {
            width: 15px;
            height: 15px;

            &:hover {
                width: 20px;
                height: 20px;

                transition: .2s;
            }
        }
    }
}
</style>