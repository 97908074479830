<template>
    <section id="about" class="AboutUs">
        <div class="Wrapper">
            <div class="AboutUs-List">
                <div v-for="(aboutUsItem, i) in aboutUsItems" :key="i" class="AboutUs-Item">
                    <div v-if="aboutUsItem.number != ''" class="AboutUs-ItemOdometer TextCenter">
                        <span class="AboutUs-ItemTitle">{{ aboutUsItem.number + aboutUsItem.sub }}</span>
                    </div>
                    <div class="AboutUs-ItemText LiteTextWeight TextCenter" v-html="aboutUsItem.text">
                    </div>
                    <div class="AboutUs-Shadow"></div>
                </div>
                <img v-show="$screen.width > 959" src="@/assets/images/about-dots.png" alt="bg" class="AboutUs-BgDots">
            </div>
            <div class="Button-MoreDetails">
                <button @click="showModal" class="ModalAboutUs-Button Button Button--green">
                    <span v-if="$i18n.locale === 'ru'">{{ 'Подробнее' }}</span>
                    <span v-if="$i18n.locale === 'en'">{{ 'More details' }}</span>
                </button>
                <Modal ref="customModal">
                    <div v-for="(modalAboutUsTextItem, i) in modalAboutUsTextItems" :key="i" class="ModalAboutUs-Text">
                        {{ modalAboutUsTextItem.text }}
                        <div class="Shadow"></div>
                    </div>
                </Modal>
            </div>
        </div>
    </section>
</template>

<script>
import Modal from './ModalAboutUs.vue';

export default {
    name: "AboutUs",
    components: {
        Modal
    },
    data() {
        return {
            number: 0,
            dig: 100,
            aboutUsItems: [
                { number: 400, sub: "+", text: this.$t('aboutUsItems.text1') },
                { number: 100, sub: "+", text: this.$t('aboutUsItems.text2') },
                { number: 9, sub: "", text: this.$t('aboutUsItems.text3') },
                { number: "", sub: "", text: this.$t('aboutUsItems.text4') },
                { number: "", sub: "", text: this.$t('aboutUsItems.text5') },
                { number: "", sub: "", text: this.$t('aboutUsItems.text6') },
                { number: "", sub: "", text: this.$t('aboutUsItems.text7') },
                { number: "", sub: "", text: this.$t('aboutUsItems.text8') },
                { number: "", sub: "", text: this.$t('aboutUsItems.text9') },
            ],
            modalAboutUsTextItems: [
                { text: this.$t('modalAboutUsTextItems.text1') },
                { text: this.$t('modalAboutUsTextItems.text2') },
                { text: this.$t('modalAboutUsTextItems.text3') },
                { text: this.$t('modalAboutUsTextItems.text4') }
            ]
        }
    },
    methods: {
        showModal() {
            this.$refs.customModal.open();
        }
    }
}
</script>

<style lang="scss">
.AboutUs {
    position: relative;
    padding: 40px 0;
    margin-top: 92px; // Height of MainMenu
    z-index: 1;

    @media (max-width: $screen-l) {
        margin-top: 68px;
    }

    &-List {
        position: relative;
        display: grid;
        grid-template-columns: minmax(280px, 1fr);
        gap: 20px;
        margin: 0 auto;
        padding: 0 15px;
        max-width: 1200px;

        @media (min-width: $screen-l) {
            grid-template-columns: repeat(3, minmax(280px, 300px));
            justify-content: center;
        }

        @media (min-width: $screen-xl) {
            grid-template-columns: repeat(3, minmax(300px, 350px));
        }
    }

    &-Item {
        position: relative;
        border: 2px solid var(--color-text-main2);
        padding: 20px;
        min-height: 150px;
        display: grid;
        align-content: center;
        justify-content: center;
    }

    &-ItemOdometer {
        font-size: clamp(50px, 5vw, 78px);
        color: var(--color-text-main2);
        z-index: 1;
    }

    &-Shadow {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(5px);
        background: var(--color-body);
        z-index: 0;
        width: 90%;
        height: 90%;
        max-width: 300px;
        max-height: 150px;
    }

    &-BgDots {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-20%, -50%);
        z-index: 0;
        opacity: 0.3;
        max-width: 100%;
        height: auto;
    }

    .LiteTextWeight {
        font-weight: 10;
        z-index: 1;
    }

    .TextCenter {
        text-align: center;
    }
}

.Button-MoreDetails {
    width: fit-content;
    margin: auto;

    &:hover {
        .Button {
            background: var(--color-body);
            color: var(--color-text-main);
            transition: all .2s;
        }
    }
}
</style>